ul {
  list-style: none;
  margin: $ul-margin;
  padding: 0;
  li {
    margin-bottom: 10px;
    padding-left: $li-content-distance-from-left;
    position: relative;
    font-size: $li-content-size;
    line-height: $li-content-size*1.5;
    &::before {
      content: $li-content;
      font-size: $li-content-size;
      top: $li-content-distance-from-top;
      color: $li-content-color;
      position: absolute;
      left: 0;
    }
  }
}

ol {
  counter-reset: item;
  list-style: none;
  margin: $ul-margin;
  padding: 0;
  li {
    margin-bottom: 10px;
    padding-left: $li-content-distance-from-left;
    position: relative;
    &::before {
      content: counters(item, ".") " ";
      counter-increment: item;
      font-size: $li-content-size;
      top: $li-content-distance-from-top;
      color: $li-content-color;
      position: absolute;
      left: 0;
    }
  }
}