.button {
  font-family: $button-font;
  font-weight: $button-font-weight;
  padding: $button-padding;
  background-color: $button-background-color;
  color: $button-font-color!important;
  font-size: $button-font-size;
  text-transform: $button-text-transform;
  border: $button-border;
  transition: $button-transition;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    color: $button-font-color-hover!important;
    background-color: $button-background-color-hover;
    color: $button-font-color-hover;
    border: $button-border-hover;
    text-decoration: none;
  }
}