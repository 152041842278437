.white {
  color: #fff;
}
.black {
  color: #000;
}
.light-gray {
  color: $light-gray;
}
.medium-gray {
  color: $medium-gray;
}
.dark-gray {
  color: $dark-gray;
}
.primary-color {
  color: $primary-color;
}
.secondary-color {
  color: $secondary-color;
}
.tertiary-color {
  color: $tertiary-color;
}
.quaternary-color {
  color: $quaternary-color;
}
.white-bg {
  color: #fff;
}
.black-bg {
  background-color: #000;
}
.light-gray-bg {
  background-color: $light-gray;
}
.medium-gray-bg {
  background-color: $medium-gray;
}
.dark-gray-bg {
  background-color: $dark-gray;
}
.primary-color-bg {
  background-color: $primary-color;
}
.secondary-color-bg {
  background-color: $secondary-color;
}
.tertiary-color-bg {
  background-color: $tertiary-color;
}
.quaternary-color-bg {
  background-color: $quaternary-color;
}