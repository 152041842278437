@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Lato:400,400i|Libre+Baskerville:400,700");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* KIM'S FRAMEWORK */
/* GRID SYSTEM */
.bs_row {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- */
  display: -ms-flexbox;
  /* IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  /* change directions */
}

.bs_row.bs_row.bs_direction_column {
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
}

.bs_row.bs_row.bs_direction_row {
  flex-direction: row;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
}

.bs_one,
.bs_two,
.bs_three,
.bs_four,
.bs_five,
.bs_six,
.bs_seven,
.bs_eight {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
  /* to fix FF 36 responsive images bug: http://stackoverflow.com/questions/28710379/firefox-flexbox-image-width */
}

.bs_one img,
.bs_two img,
.bs_three img,
.bs_four img,
.bs_five img,
.bs_six img,
.bs_seven img,
.bs_eight img {
  height: auto;
  max-width: 100%;
  align-self: center;
  display: block;
}

.bs_two {
  -webkit-box-flex: 2;
  -moz-box-flex: 2;
  -webkit-flex: 2;
  -ms-flex: 2;
  flex: 2;
}

.bs_three {
  -webkit-box-flex: 3;
  -moz-box-flex: 3;
  -webkit-flex: 3;
  -ms-flex: 3;
  flex: 3;
}

.bs_four {
  -webkit-box-flex: 4;
  -moz-box-flex: 4;
  -webkit-flex: 4;
  -ms-flex: 4;
  flex: 4;
}

.bs_five {
  -webkit-box-flex: 5;
  -moz-box-flex: 5;
  -webkit-flex: 5;
  -ms-flex: 5;
  flex: 5;
}

.bs_six {
  -webkit-box-flex: 6;
  -moz-box-flex: 6;
  -webkit-flex: 6;
  -ms-flex: 6;
  flex: 6;
}

.bs_seven {
  -webkit-box-flex: 7;
  -moz-box-flex: 7;
  -webkit-flex: 7;
  -ms-flex: 7;
  flex: 7;
}

.bs_eight {
  -webkit-box-flex: 8;
  -moz-box-flex: 8;
  -webkit-flex: 8;
  -ms-flex: 8;
  flex: 8;
}

/* the following is to fix FF 36 responsive images bug: http://stackoverflow.com/questions/28710379/firefox-flexbox-image-width */
@-moz-document url-prefix() {
  .bs_one img, .bs_two img, .bs_three img, .bs_four img, .bs_five img, .bs_six img, .bs_seven img, .bs_eight img {
    max-width: 100%;
  }
}

.bs_flexItems {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- */
  display: -ms-flexbox;
  /* IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.bs_blockItems {
  display: block;
  width: 100%;
}

/* ALIGNMENTS */
.bs_text_left {
  text-align: left;
}

.bs_text_right {
  text-align: right;
}

.bs_text_center {
  text-align: center;
}

.bs_text_justify {
  text-align: justify;
}

.bs_fullScreen {
  height: 100%;
  position: absolute;
  width: 100%;
}

.bs_horizontallyCentered {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
}

.bs_verticallyCentered {
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.bs_top {
  align-items: flex-start;
  align-self: flex-start;
}

.bs_bottom {
  align-items: flex-end;
  align-self: flex-end;
}

.bs_centered {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}

.bs_left {
  -webkit-box-pack: flex-start;
  -moz-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.bs_right {
  -webkit-box-pack: flex-end;
  -moz-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  text-align: right;
}

.bs_marginAuto {
  margin: auto;
}

/* floats */
.bs_floatLeft {
  float: left;
}

.bs_floatRight {
  float: right;
}

.bs_clearFloat {
  width: 100%;
  clear: both;
}

/* RESPONSIVE 16x9 VIDEOS */
.bs_video16x9 {
  position: absolute;
  display: block;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.bs_video16x9 > div {
  width: 100%;
  height: 100%;
}

.bs_videoContainer {
  position: relative;
  height: 0;
  padding: 56.25% 0 0 0;
}

/* TABLET & MEDIUM SCREENS */
@media screen and (max-width: 1200px) {
  /* change row direction to column on tablet and mobile */
  .bs_row.bs_changeColumnDirection {
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
  }
  .bs_centeredOnTablet {
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
  }
  /* change row direction to column */
  .bs_changeTabletDirection {
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
  }
}

/* MOBILE & SMALL SCREENS */
@media all and (max-width: 768px) {
  /* change row direction to column */
  .bs_row {
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
  }
  .bs_centeredOnMobile {
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
  }
  .bs_floatNoneOnMobile {
    float: none;
  }
}

/* paddings and margins */
.bs_padding0 {
  padding: 0;
}

.bs_padding1 {
  padding: 0 1% 0 1%;
}

.bs_padding2 {
  padding: 0 2% 0 2%;
}

.bs_padding3 {
  padding: 0 3% 0 3%;
}

.bs_padding4 {
  padding: 0 4% 0 4%;
}

.bs_padding5 {
  padding: 0 5% 0 5%;
}

.bs_padding6 {
  padding: 0 6% 0 6%;
}

.bs_padding7 {
  padding: 0 7% 0 7%;
}

.bs_padding8 {
  padding: 0 8% 0 8%;
}

.bs_padding8 {
  padding: 0 9% 0 9%;
}

.bs_padding10 {
  padding: 0 10% 0 10%;
}

.bs_margin0 {
  margin: 0;
}

.bs_margin5 {
  margin-bottom: 5px;
}

.bs_margin10 {
  margin-bottom: 10px;
}

.bs_margin15 {
  margin-bottom: 15px;
}

.bs_margin20 {
  margin-bottom: 20px;
}

.bs_margin25 {
  margin-bottom: 25px;
}

.bs_margin30 {
  margin-bottom: 30px;
}

.bs_margin35 {
  margin-bottom: 35px;
}

.bs_margin40 {
  margin-bottom: 40px;
}

.bs_margin45 {
  margin-bottom: 45px;
}

.bs_margin50 {
  margin-bottom: 50px;
}

.bs_margin60 {
  margin-bottom: 60px;
}

.bs_margin70 {
  margin-bottom: 70px;
}

.bs_margin80 {
  margin-bottom: 80px;
}

.bs_margin90 {
  margin-bottom: 90px;
}

.bs_margin100 {
  margin-bottom: 100px;
}

/* HIDE & SHOW */
.bs_show, .bs_showOnDesktop {
  display: block;
}

.bs_hide {
  display: none;
}

@media all and (min-width: 1201px) {
  .bs_hideOnDesktop {
    display: none;
  }
}

@media all and (max-width: 1200px) and (min-width: 768px) {
  .bs_hideOnTablet {
    display: none;
  }
}

@media all and (max-width: 768px) {
  .bs_hideOnMobile {
    display: none;
  }
}

.bs_rowCustom {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.bs_3to2,
.bs_2to2,
.bs_4to2,
.bs_5to2,
.bs_6to2,
.bs_4to2to1 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
  /* to fix FF 36 responsive images bug: http://stackoverflow.com/questions/28710379/firefox-flexbox-image-width */
}

.bs_3to2 img,
.bs_2to2 img,
.bs_4to2 img,
.bs_5to2 img,
.bs_6to2 img,
.bs_4to2to1 img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  align-self: center;
  display: block;
}

/* the following is to fix FF 36 responsive images bug: http://stackoverflow.com/questions/28710379/firefox-flexbox-image-width */
@-moz-document url-prefix() {
  .bs_3to2 img,
  .bs_2to2 img,
  .bs_4to2 img,
  .bs_5to2 img,
  .bs_6to2 img,
  .bs_4to2to1 img {
    max-width: 100%;
    width: 100%;
  }
}

.bs_2to2 {
  margin: 0 2% 2% 2%;
  flex-basis: 46% !important;
}

.bs_3to2 {
  flex-basis: 30% !important;
  margin: 0 1.6% 1.6% 1.6%;
  align-items: flex-end;
}

.bs_4to2,
.bs_4to2to1 {
  flex-basis: 23% !important;
  margin: 0 1% 1% 1%;
  align-items: flex-end;
}

.bs_5to2 {
  flex-basis: 18% !important;
  margin: 0 1% 1% 1%;
  align-items: flex-end;
}

.bs_6to2 {
  flex-basis: 14.6% !important;
  margin: 0 1% 1% 1%;
  align-items: flex-end;
}

@media all and (max-width: 1200px) {
  .bs_3to2,
  .bs_4to2,
  .bs_5to2,
  .bs_6to2,
  .bs_4to2to1 {
    margin: 0 2% 2% 2%;
    flex-basis: 46% !important;
  }
}

@media all and (max-width: 768px) {
  .bs_rowCustom {
    flex-direction: row;
  }
  .bs_4to2to1 {
    margin: 0 2% 2% 2%;
    flex-basis: 98% !important;
  }
  .bs_bringToTop {
    order: -1;
  }
  .bs_bringToBottom {
    order: 999;
  }
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

h1 {
  font-family: "Libre Baskerville", serif;
  font-size: 60px;
  font-weight: 400;
  line-height: 1.25em;
  color: #005199;
  margin-bottom: 30px;
  text-transform: none;
}

h1.blue {
  color: #005199;
}

h2 {
  font-family: "Libre Baskerville", serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 1.25em;
  color: #FE6847;
  margin-bottom: 30px;
  text-transform: none;
}

h2.fancy {
  font-size: 45px;
  color: #FE6847;
  outline: none;
}

@media screen and (max-width: 768px) {
  h2.fancy {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 25px;
  }
}

h3 {
  font-family: "Libre Baskerville", serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.5em;
  color: #006CCD;
  margin-bottom: 20px;
  text-transform: none;
  letter-spacing: .25px;
}

h3.white {
  color: white;
}

@media screen and (max-width: 768px) {
  h3 {
    font-size: 30px;
  }
}

h4 {
  font-family: "Libre Baskerville", serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5em;
  color: black;
  margin-bottom: 20px;
  text-transform: capitalize;
}

h4.black {
  color: black;
}

p {
  font-family: "Lato", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5em;
  color: black;
  margin-bottom: 30px;
  text-transform: none;
}

p.white {
  color: white;
}

a,
a:visited,
a:active {
  color: #005199;
  text-decoration: none;
}

a:hover {
  color: #FE6847;
  text-decoration: none;
  cursor: pointer;
}

.white {
  color: #fff;
}

.black {
  color: #000;
}

.light-gray {
  color: #f0f0f0;
}

.medium-gray {
  color: #ccc;
}

.dark-gray {
  color: #4d4d4f;
}

.primary-color {
  color: #005199;
}

.secondary-color {
  color: #FE6847;
}

.tertiary-color {
  color: #006CCD;
}

.quaternary-color {
  color: #4d4d4f;
}

.white-bg {
  color: #fff;
}

.black-bg {
  background-color: #000;
}

.light-gray-bg {
  background-color: #f0f0f0;
}

.medium-gray-bg {
  background-color: #ccc;
}

.dark-gray-bg {
  background-color: #4d4d4f;
}

.primary-color-bg {
  background-color: #005199;
}

.secondary-color-bg {
  background-color: #FE6847;
}

.tertiary-color-bg {
  background-color: #006CCD;
}

.quaternary-color-bg {
  background-color: #4d4d4f;
}

.button {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  padding: 15px 30px;
  background-color: #FE6847;
  color: white !important;
  font-size: 16px;
  text-transform: capitalize;
  border: 1px solid #FE6847;
  transition: 0.25s all linear;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
}

.button:hover {
  color: #FE6847 !important;
  background-color: white;
  color: #FE6847;
  border: 1px solid #FE6847;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0 0 30px 0;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
  font-size: 17px;
  line-height: 25.5px;
}

ul li::before {
  content: "•";
  font-size: 17px;
  top: 0;
  color: #005199;
  position: absolute;
  left: 0;
}

ol {
  counter-reset: item;
  list-style: none;
  margin: 0 0 30px 0;
  padding: 0;
}

ol li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

ol li::before {
  content: counters(item, ".") " ";
  counter-increment: item;
  font-size: 17px;
  top: 0;
  color: #005199;
  position: absolute;
  left: 0;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body,
html {
  background-color: white;
  font-family: "Lato", sans-serif;
  letter-spacing: .8px;
  color: black;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

img {
  max-width: 100%;
  height: auto;
}

a,
a:visited,
a:active {
  color: #005199;
  text-decoration: none;
  transition: .2s all linear;
}

a:hover,
a:visited:hover,
a:active:hover {
  color: #FE6847;
  text-decoration: none;
  cursor: pointer;
}

a:hover.button,
a:visited:hover.button,
a:active:hover.button {
  text-decoration: none;
}

hr {
  border: 0;
  height: 1px;
  background: #ccc;
  margin: 60px 0;
}

@media screen and (max-width: 768px) {
  hr {
    margin: 20px 0 40px 0;
  }
}

@media all and (max-width: 1310px) {
  br.desktop {
    display: none;
  }
}

section.padding {
  padding: 100px 0;
}

@media screen and (max-width: 768px) {
  section.padding {
    padding: 40px 0;
  }
}

section.padding-top {
  padding-top: 100px;
}

@media screen and (max-width: 768px) {
  section.padding-top {
    padding-top: 40px;
  }
}

.container {
  position: relative;
  width: calc(100% - 40px);
  max-width: calc(1600px - 40px);
  margin: auto;
}

.container.smaller {
  width: calc(100% - 160px);
  max-width: calc(1600px - 160px);
}

@media all and (max-width: 1220px) {
  .container.smaller {
    width: calc(100% - 40px);
  }
}

.width67 {
  max-width: 67%;
}

@media all and (max-width: 1220px) {
  .width67 {
    max-width: 100%;
  }
}

.left-margin {
  margin-left: 40px;
}

@media all and (max-width: 768px) {
  .left-margin {
    margin-left: 0;
  }
}

.right-margin {
  margin-right: 40px;
}

@media all and (max-width: 768px) {
  .right-margin {
    margin-right: 0;
  }
}

.top-margin {
  margin-top: 10px;
}

.no-margin {
  margin: 0;
}

.hero-secondary {
  padding: 50px 0;
  background: #f0f0f0 url("../images/secondary-hero.jpg") no-repeat center center;
  background-size: cover;
  min-height: 80px;
}

.hero-secondary h1 {
  text-transform: capitalize;
  line-height: 80px;
  color: white;
  font-weight: 400;
  max-width: 650px;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .hero-secondary h1 {
    line-height: 55px;
    font-size: 45px;
  }
}

.hero-secondary p {
  color: white;
  margin-bottom: 10px;
}

@media screen and (max-width: 1350px) {
  .hero-secondary {
    margin-top: 80px;
  }
}

header {
  z-index: 20;
}

header .container {
  width: calc(1600px - 160px);
  max-width: calc(100% - 160px);
}

@media screen and (max-width: 1350px) {
  header .container {
    max-width: calc(100% - 40px);
  }
}

header .container .bs_row {
  height: 80px;
}

@media screen and (max-width: 1350px) {
  header .container .bs_row .bs_three {
    flex: 6;
  }
}

@media screen and (max-width: 1350px) {
  header .container .bs_row .bs_six {
    flex: 1;
  }
}

@media screen and (max-width: 1350px) {
  header {
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
  }
}

nav {
  font-family: "Libre Baskerville", serif;
  letter-spacing: .1px;
  z-index: 30;
  margin-bottom: 15px;
}

nav #trigger {
  display: none;
  cursor: pointer;
}

@media screen and (max-width: 1350px) {
  nav #trigger {
    display: block;
  }
}

nav .button {
  margin-left: 20px;
}

@media screen and (max-width: 1350px) {
  nav .button {
    margin-left: 0;
  }
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 15px 0 0 0;
  transition: linear 500ms;
}

nav ul a,
nav ul a:visited,
nav ul a:active {
  color: #4d4d4f;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
}

nav ul a:hover {
  color: #4d4d4f !important;
  text-decoration: none;
  border-bottom: 2px solid #FE6847;
}

nav ul li {
  display: inline-block;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0 15px;
  padding: 0;
}

@media screen and (max-width: 1350px) {
  nav ul li {
    color: #005199;
    display: block;
    border-bottom: solid 1px white;
    padding: 20px 20px 0 20px;
    margin: 0;
  }
}

nav ul li::before {
  content: '';
}

@media screen and (max-width: 1350px) {
  nav ul {
    width: 300px;
    background-color: white;
    position: fixed;
    top: 70px;
    bottom: 0;
    height: 100%;
    right: calc(-100%);
  }
  nav ul.open {
    right: 0;
  }
}

nav .hamburger {
  position: fixed;
  right: 30px;
  top: 20px;
  width: 2em;
  height: 45px;
  z-index: 5;
}

nav .hamburger div {
  position: relative;
  width: 3em;
  height: 5px;
  border-radius: 3px;
  background-color: #005199;
  margin-top: 6px;
  transition: all 0.2s ease-in-out;
}

nav #toggle {
  display: none;
}

nav #toggle:checked + .hamburger .top-bun {
  transform: rotate(-45deg);
  margin-top: 18px;
}

nav #toggle:checked + .hamburger .bottom-bun {
  opacity: 0;
  transform: rotate(45deg);
}

nav #toggle:checked + .hamburger .meat {
  transform: rotate(45deg);
  margin-top: -5px;
}

nav #toggle:checked + .hamburger + .nav {
  top: 0;
  transform: scale(1);
}

.logo {
  font-family: "Libre Baskerville", serif;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: .1px;
}

.logo a,
.logo a:visited,
.logo a:active {
  color: black;
}

.logo a:hover {
  color: black !important;
  text-decoration: none;
}

@media screen and (max-width: 1350px) {
  .logo {
    margin-left: -10px;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    font-size: 25px;
  }
}

footer {
  background-image: linear-gradient(to right, #4d4d4f 70%, black 30%);
}

@media all and (max-width: 768px) {
  footer {
    background-image: none;
  }
  footer img {
    padding: 40px 0;
  }
  footer .container {
    padding: 0;
  }
}

@media all and (max-width: 768px) and (max-width: 768px) {
  footer .container {
    width: 100%;
  }
}

footer .footerCopy {
  padding: 50px 50px 50px 0;
}

@media all and (max-width: 768px) {
  footer .footerCopy {
    padding: 40px;
  }
}

footer p {
  font-size: 14px;
  color: white;
  margin-bottom: 20px;
}

footer p:last-of-type {
  margin-bottom: 0;
}

footer h3 {
  color: white;
  margin: 0;
  padding: 20px;
  text-align: center;
}

.home .hero {
  background: #f0f0f0 url("../images/home-hero-large.jpg") no-repeat center center;
  height: 600px;
  width: 100%;
  position: relative;
}

.home .hero .hero-content {
  position: absolute;
  bottom: 60px;
  left: 80px;
}

.home .hero .hero-content h1 {
  text-transform: capitalize;
  line-height: 70px;
  font-size: 60px;
  margin: 0 0 30px 0;
  color: white;
  font-weight: 700;
  max-width: 650px;
}

@media screen and (max-width: 768px) {
  .home .hero .hero-content h1 {
    line-height: 55px;
    font-size: 45px;
  }
}

@media screen and (max-width: 1350px) {
  .home .hero .hero-content {
    left: 15px;
    margin-right: 15px;
  }
}

.home .practice-areas {
  padding: 60px;
  background: white;
  padding: 40px 40px 60px 40px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(143, 143, 143, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(143, 143, 143, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(143, 143, 143, 0.5);
}

.home .practice-areas h3 {
  color: #005199;
}

.home .practice-areas.blue {
  background: #005199;
}

.home .practice-areas.blue h3,
.home .practice-areas.blue p {
  color: white;
}

@media all and (max-width: 768px) {
  .home .practice-areas {
    margin-bottom: 30px;
  }
}

.home #about {
  background: white url("../images/city-gray-bg.jpg") no-repeat bottom center;
}

.contact .icon {
  float: left;
  margin-right: 20px;
  transition: .2s all linear;
}

.contact .icon:hover {
  opacity: .7;
}

.practice-areas aside {
  margin-top: 40px;
}

.practice-areas aside ol li {
  margin-bottom: 20px;
  font-family: "Libre Baskerville", serif;
  color: #005199;
  line-height: 25px;
}

@media screen and (max-width: 768px) {
  .practice-areas aside {
    margin-top: 0;
  }
}

.press-releases a:hover {
  opacity: .6;
}

.attorneys .details {
  display: none;
}
